/* You can add global styles to this file, and also import other style files */
@import "styles/root";
@import "styles/manager";

.pointer {
    cursor: pointer;

    &.hover:hover {
        background:$table-hover-bg;
    }
}

/* OVERRIDES */
.badge-info {
    color: #0097A7;
    background-color: #D2F9FD;
}

/* Weird character shows up in toastr messages unlesse we do this... it comes from legacy components.css */
#toast-container>.toast-info:before,
#toast-container>.toast-success:before {
    content: none !important;
}

/* We don't have a panel item component (see Users Details => Applications) */
.sk-panel-list-item {
    border-bottom:1px solid #e1e4e6;
    cursor: pointer;

    &:hover {
        background:$active-state-bg;
    }
}


/* Deborah wanted a little more room for CM since the panel is a major component */
.sk-panel-right {
    width:31rem !important;
}

/* UTILITIES */
.sk-dropdown-custom.dropdown-toggle::after {
    display: none;
}

.sk-resources-updating {
    opacity: 0.5;
}

th.sk-column-fit {
    width:1px;
}

.hover-date {
    background-color: #2C3449;

    .arrow:after {
        border-top-color: #2C3449;
    }

    .popover-body {
        color: white;
    }
}

.sk-navbar-primary h3+div.d-flex {
    display: none !important;
}
