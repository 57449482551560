/* You can add global styles to this file, and also import other style files */
.sk-panel-list .sk-panel-list-item {
  cursor: pointer;
  padding: 1rem;
  border-bottom: 1px solid #f0f8ff;
}
.sk-panel-list .sk-panel-list-item:hover {
  background: #f0f8ff;
}
.sk-panel-list .sk-panel-list-item:first-of-type {
  border-top: 1px solid #e1e4e6;
}
.sk-panel-list .sk-panel-list-item-template {
  color: #999;
  padding: 10px 0;
}

.sk-card-wrap {
  display: flex;
  flex-wrap: wrap;
}

.sk-spinner-wrap .sk-spinner {
  margin: 0 auto;
}

.sk-icon-btn {
  background: none;
  border: none;
  vertical-align: middle;
  height: 40px;
  width: 40px;
}
.sk-icon-btn .material-icons {
  vertical-align: middle;
}

.sk-dialog .sk-dialog-footer {
  background: aliceblue;
  padding: 1rem;
  text-align: right;
  border-top: 1px solid #eee;
}
.sk-dialog .sk-dialog-body {
  padding: 1rem;
}
.sk-dialog .sk-dialog-header {
  padding: 1rem;
  background: aliceblue;
  border-bottom: 1px solid #eee;
}
.sk-dialog input.sk-link {
  width: 300px;
  padding: 4px;
}

.pointer {
  cursor: pointer;
}
.pointer.hover:hover {
  background: #f0f8ff;
}

/* OVERRIDES */
.badge-info {
  color: #0097A7;
  background-color: #D2F9FD;
}

/* Weird character shows up in toastr messages unlesse we do this... it comes from legacy components.css */
#toast-container > .toast-info:before,
#toast-container > .toast-success:before {
  content: none !important;
}

/* We don't have a panel item component (see Users Details => Applications) */
.sk-panel-list-item {
  border-bottom: 1px solid #e1e4e6;
  cursor: pointer;
}
.sk-panel-list-item:hover {
  background: #f4f7fa;
}

/* Deborah wanted a little more room for CM since the panel is a major component */
.sk-panel-right {
  width: 31rem !important;
}

/* UTILITIES */
.sk-dropdown-custom.dropdown-toggle::after {
  display: none;
}

.sk-resources-updating {
  opacity: 0.5;
}

th.sk-column-fit {
  width: 1px;
}

.hover-date {
  background-color: #2C3449;
}
.hover-date .arrow:after {
  border-top-color: #2C3449;
}
.hover-date .popover-body {
  color: white;
}

.sk-navbar-primary h3 + div.d-flex {
  display: none !important;
}