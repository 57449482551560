@import "root";

.sk-panel-list {

    .sk-panel-list-item {
        cursor: pointer;
        padding: 1rem;
        border-bottom: 1px solid $table-hover-bg;

        &:hover {
            background:$table-hover-bg;
        }

        &:first-of-type {
            border-top: 1px solid #e1e4e6;
        }
    }

    .sk-panel-list-item-template {
        color: #999;
        padding:10px 0;
    }
}

.sk-card-wrap {
    display:flex;
    flex-wrap: wrap;
}

.sk-spinner-wrap {
    .sk-spinner {
        margin:0 auto;
    }
}

.sk-icon-btn {
    background: none;
    border: none;
    vertical-align: middle;
    height: 40px;
    width: 40px;

    .material-icons {
        vertical-align: middle;
    }
}

.sk-dialog {
    .sk-dialog-footer {
        background: aliceblue;
        padding: 1rem;
        text-align: right;
        border-top:1px solid #eee;
    }
    
    .sk-dialog-body {
        padding: 1rem;
    }
    
    .sk-dialog-header {
        padding: 1rem;
        background: aliceblue;
        border-bottom:1px solid #eee;
    }

    input.sk-link {
        width: 300px;
        padding:4px;
    }
}